import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']
  static values = {
    currentUser: String
  };

  clearInput (event) {
    if (this.hasInputTarget) {
      this.inputTarget.value = ''
      console.log('Input cleared successfully!')
    }
  }

  editComment (event) {
    const commentOwnerId = event.currentTarget.dataset.commentOwner

    if (this.hasCurrentUserValue) {
      if (commentOwnerId !== this.currentUserValue) {
        event.preventDefault()
      }
    }
  }

  toggleDropdown (event) {
    const dropdownId = event.currentTarget.id.replace('button-', 'dropdown-')
    const dropdown = document.getElementById(dropdownId)
    const icon = event.currentTarget.querySelector('svg')

    if (dropdown && icon) {
      dropdown.classList.toggle('hidden')
      icon.classList.toggle('-rotate-90')
    }
  }
}
