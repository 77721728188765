import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class HeartbeatsController extends Controller {
  async connect () {
    this.initializeTomSelects()
  }

  initializeTomSelects () {
    document.querySelectorAll('select').forEach((elem) => {
      this.tomSelect = new TomSelect(`#${elem.id}`, {
        create: false,
        sortField: {
          field: 'text',
          direction: 'asc'
        }
      })
    })
  }
}
